import _ from "lodash";

const mapField = (field, getter, setter) => ({
  get() {
    return getter(this, field);
  },
  set (value) {
    setter(this, field, value);
  },
});

/** @url https://github.com/maoberlehner/vuex-map-fields/issues/24 */
export const mapFields = ({ prefix = 'form', formSetup, props = ['fields'], getter, setter }) => {
  let fields = Object.keys(formSetup),
      names = fields.map(i => prefix ? `${prefix}_${i.replaceAll('.', '_')}` : i.replaceAll('.', '_')),
      obj = {}

  // fields
  if (props.includes('fields')) {
    obj = fields.reduce((map, field, idx) => ({
      ...map,
      [names[idx]]: mapField(field, getter, setter),
    }), {})
  }

  // formState
  if (props.includes('formState')) {
    obj.formState = ((names) => ({
      get() { return _.pick(this, names) }
    }))(names)
  }

  // formRules
  if (props.includes('formRules')) {
    obj.formRules = ((names, formSetup) => ({
      get() { return Object.entries(formSetup).reduce((map, [key, val], idx) => ({
        ...map,
        [names[idx]]: val.rules
      }), {}) }
    }))(names, formSetup)
  }

  return obj
};
