<template>
<AuthLayout>
<template #content>
  
  <div class="CommentPage">
    
    <div
      class="CommentPage__title"
    ><!--
   -->Comments
    </div>
    
    <div class="CommentPage__body">
      
      <div class="CommentPage__paragraph">
        If any other symptoms or bowel movements occured during the test, please note them in the comments box below.
      </div>
      
      <a-form
        ref="form"
        :model="formState"
        layout="vertical"
        validateTrigger="onSubmit"
        :hideRequiredMark="true"
        @finish="onSubmit"
      >
        
        <a-form-item
          class="ant-form-item-primary"
          name="form_patient_comments"
          :label="formSetup.patient_comments.label"
        >
          <a-textarea
            v-model:value="form_patient_comments"
            :disabled="loading || request?.status === 'test_completed'"
            size="large"
            :auto-size="{ minRows: 5, maxRows: 5 }"
            :maxlength="250"
          />
        </a-form-item>
        
        <div class="CommentPage__count">
          {{ `${form_patient_comments?.length || 0} / 250` }}
        </div>
        
        <a-button
          class="ant-btn-huge CommentPage__submit"
          type="green"
          size="large"
          html-type="submit"
          :loading="loading"
          :disabled="request?.status === 'test_completed'"
        >
          Save
        </a-button>
        
      </a-form>
      
    </div>
    
  </div>
  
</template>
</AuthLayout>
</template>

<script>
import { mapFields } from '@/helpers/form.helper'
import AuthLayout from "@/components/patient/auth.layout/AuthLayout"
import { mapActions, mapGetters, mapMutations } from "vuex"
import { isSuccess } from "@/helpers/http.helper"
import { toast } from "@/helpers/error.helper"
import { PatientRequestService } from '@/services/api_v1/patient'
import { ApiResult } from "@/models"
import { Request } from '@/models/api_v1'

let serverErr, formSetup = {
  patient_comments: {
    label: 'Comments',
    // rules: [],
  },
}

export default {
  name: "CommentPage",
  components: {
    AuthLayout,
  },
  data() {
    return {
      loading: false,
      formSetup,
    }
  },
  computed: {
    ...mapGetters({
      isRequest: 'PatientRequestStore/getIsInit',
      request: 'PatientRequestStore/getInit',
    }),
    ...mapFields({
      formSetup,
      props: ['fields', 'formState'],
      getter: (vm, field) => vm.request[field],
      setter: (vm, field, value) => vm.setRequestField({ field, value}),
    }),
  },
  methods: {
    ...mapActions({
      fetchRequest: 'PatientRequestStore/fetchInit',
      updatePatient: 'PatientPatientStore/update',
    }),
    ...mapMutations({
      setRequest: 'PatientRequestStore/setItemInit',
      setRequestField: 'PatientRequestStore/setItemInitField',
    }),
    async onSubmit() {
      let payload = this.request.toApi()
      this.loading = true
      await PatientRequestService.update(payload)
        .then((response) => {
          if (response instanceof Error || !isSuccess(response.status)) throw response
          let result = new ApiResult(response),
              item = Request.fromApi(result.data.data)
          this.setRequest(item)
          toast ({ type: 'success', msg: 'Success' })
          this.$router.push({ name: 'patient.samples' })
        })
        .catch((err) => {
          toast ({ msg: err?.data?.data?.message })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  async mounted() {
    if (!this.isRequest) {
      await this.fetchRequest()
    }
  },
}
</script>

<style lang="scss" scoped>
.CommentPage {
  padding: 0 15px;
  
  &__body {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 15px;
  }
  
  &__title {
    color: $polarGreen7;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    white-space: break-spaces;
  }
  
  &__paragraph {
    color: $primary10;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  
  &__count {
    color: #bfbfbf;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-top: -15px;
    margin-bottom: 24px;
    text-align: right;
  }
  
}
</style>